import React,{Suspense, useState, Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {BrowserRouter as Router,Route,Redirect} from 'react-router-dom'
import Header from './components/Header'
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import {Typography} from '@material-ui/core'
import  {APP_URL} from './config.json';

import {pathSpliter,authInfoSeter,accessChecker} from './lib/functions'
import {currentRouteSet,authInfoSet} from './actions/actions'; 
import CustomerTransactionHistory from './components/customer/customer_transaction-history';
import salesReturnList from './components/sales/sales_return_list'; 
import ProductList from './components/administration/product_list';
import SupplierTransactionHistory from './components/supplier/supplier_transaction_history';
import productLedger from './components/stock/product_ledger';
import MaterialNameEntry from './components/production/material_name_entry';
import MaterialEntry from './components/production/material_entry';
import appointment_record from './components/marketing/appointment_record';
const Dashboard = React.lazy(() => import('./components/Dashboard'));
const ModuleDetail = React.lazy(() => import('./components/ModuleDetail'));
const UsersManage = React.lazy(() => import('./components/administration/UsersManage'));
const ProductsManage = React.lazy(() => import('./components/administration/ProductsManage'));
const SalesEntry = React.lazy(() => import('./components/sales/SalesEntry'));
const SignIn = React.lazy(() => import('./components/signin'));
const ProdCategoriesManage = React.lazy(() => import('./components/administration/prod_categories_manage'));
const CustomersManage = React.lazy(() => import('./components/administration/customers_manage'));
const CustomerLedger = React.lazy(() => import('./components/customer/customer_ledger'));
const SupplierLedger = React.lazy(() => import('./components/supplier/supplier_ledger'));
const ProdBrandsManage = React.lazy(() => import('./components/administration/prod_brands_manage'));
const ProdColorsManage = React.lazy(() => import('./components/administration/prod_colors_manage'));
const prodUnitsManage = React.lazy(() => import('./components/administration/prod_units_manage'));
const BranchesManage = React.lazy(() => import('./components/administration/branches_manage'));
const warehousesManage = React.lazy(() => import('./components/administration/warehouses_manage'));
const AreasManage = React.lazy(() => import('./components/administration/areas_manage'));
const ProdNameManage = React.lazy(() => import('./components/administration/prod_names_manage'));
const SuppliersManage = React.lazy(() => import('./components/administration/suppliers_manage'));
const DesignationsManage = React.lazy(() => import('./components/administration/designations_manage'));
const DepartmentsManage = React.lazy(() => import('./components/administration/departments_manage'));
const MonthsManage = React.lazy(() => import('./components/administration/months_manage'));
const EmployeesManage = React.lazy(() => import('./components/administration/employees_manage'));
const TransactionAccountsManage = React.lazy(() => import('./components/administration/transaction_accounts_manage'));
const BankAccountsManage = React.lazy(() => import('./components/administration/bank_accounts_manage'));
const cashTransactionManage = React.lazy(() => import('./components/administration/cash_transactions_manage'));
const BankTransactionManage = React.lazy(() => import('./components/administration/bank_transactions_manage'));

const CustomerPayments = React.lazy(() => import('./components/administration/customer_payments_manage'));
const SupplierPayments = React.lazy(() => import('./components/administration/supplier_payments_manage'));
const SalesRecord = React.lazy(() => import('./components/sales/sales_record'));
const ServiceRecord = React.lazy(() => import('./components/sales/service_record'));
const SalesReturn = React.lazy(() => import('./components/sales/sales_return'));
const PurchaseRecord = React.lazy(() => import('./components/purchase/purchase_record'));
const ServiceExpenseRecord = React.lazy(() => import('./components/sales/service_item_record'));
const PurchaseReturn = React.lazy(() => import('./components/purchase/purchase_return'));

const PurchaseEntry = React.lazy(() => import('./components/purchase/purchase_entry'));
const servicePurchaseEntry = React.lazy(() => import('./components/sales/service_purchase_entry'));
const PurchaseInvoiceQuick = React.lazy(() => import('./components/purchase/purchase_invoice_quick')); 
const ServiceItemInvoiceQuick = React.lazy(() => import('./components/sales/service_item_invoice_quick')); 
const ServiceReport = React.lazy(() => import('./components/sales/service_report')); 



const SalesInvoiceQuick = React.lazy(() => import('./components/sales/sales_invoice_quick')); 
const ServiceInvoiceQuick = React.lazy(() => import('./components/sales/service_invoice_quick')); 
const TransferInvoiceQuick = React.lazy(() => import('./components/stock/transfer_invoice_quick')); 
const productTransfer = React.lazy(() => import('./components/stock/product_transfer')); 
const StockReport = React.lazy(() => import('./components/stock/stock_report')); 
const productTransferRecord = React.lazy(() => import('./components/stock/transfer_record')); 
const ProductReceiveRecord = React.lazy(() => import('./components/stock/receive_record')); 
const ProductDamage = React.lazy(() => import('./components/stock/product_damage')); 
const ProductDamageList = React.lazy(() => import('./components/stock/product_damage_list')); 

const InstitutionProfile = React.lazy(() => import('./components/administration/institution_profile')); 

const SalaryPayment = React.lazy(() => import('./components/hrpayroll/salary_payment')); 
const SalaryPaymentReport = React.lazy(() => import('./components/hrpayroll/salary_payment_report')); 
const UserAccess = React.lazy(() => import('./components/administration/user_access')); 
const ViewSalesInvoice = React.lazy(() => import('./components/sales/view_sales_invoice')); 
const ViewServiceInvoice = React.lazy(() => import('./components/sales/view_service_invoice')); 
const ViewPurchaseInvoice = React.lazy(() => import('./components/purchase/view_purchase_invoice')); 
const ViewServiceItemInvoice = React.lazy(() => import('./components/sales/view_service_item_invoice')); 
const CustomerList = React.lazy(() => import('./components/customer/customer_list')); 
const CustomerDueList = React.lazy(() => import('./components/customer/customer_due_list')); 
const PurchaseReturnList = React.lazy(() => import('./components/purchase/purchase_return_list')); 
const supplierDueList = React.lazy(() => import('./components/purchase/supplier_due_list')); 
const supplierList = React.lazy(() => import('./components/supplier/supplier_list')); 

const cashTransactionReport = React.lazy(() => import('./components/accounts/cash_transaction_report')); 
const BankLedger = React.lazy(() => import('./components/accounts/bank_ledger')); 
const bankTransactionReport = React.lazy(() => import('./components/accounts/bank_transaction_report')); 
const CashBankBalance = React.lazy(() => import('./components/accounts/cash_bank_balance')); 
const ProfitLoss = React.lazy(() => import('./components/accounts/profit_loss')); 
const MaterialDamageEntry = React.lazy(() => import('./components/production/material_damage_entry')); 
const MaterialPurchaseEntry = React.lazy(() => import('./components/production/material_purchase_entry')); 
const ProductionEntry = React.lazy(() => import('./components/production/production_entry')); 
const MaterialProductionRecord = React.lazy(() => import('./components/production/production_record')); 
const MaterialPurchaseRecord = React.lazy(() => import('./components/production/material_purchase_record')); 
const MaterialDamageList = React.lazy(() => import('./components/production/material_damage_list')); 
const MaterialList = React.lazy(() => import('./components/production/material_list')); 
const MaterialPurchaseInvoice = React.lazy(() => import('./components/production/invoices/material_purchase_invoice')); 
const ProductionPurchaseInvoice = React.lazy(() => import('./components/production/invoices/production_invoice')); 
const MaterialPurchaseQuickInvoice = React.lazy(() => import('./components/production/material_purchase_quick_invoice')); 
const ProductionQuickInvoice = React.lazy(() => import('./components/production/production_quick_invoice')); 
const QuotationEntry = React.lazy(() => import('./components/quotation/quotation_entry')); 
const QuotationInvoice = React.lazy(() => import('./components/quotation/quotation_invoice')); 
const QuotationQuickInvoice = React.lazy(() => import('./components/quotation/quotation_quick_invoice')); 
const QuotationRecord = React.lazy(() => import('./components/quotation/quotation_record')); 
const MaterialStockReport = React.lazy(() => import('./components/production/material_stock_report')); 
const MaterialLedgerReport = React.lazy(() => import('./components/production/material_ledger_report')); 
const CustomerTransactionInvoice = React.lazy(() => import('./components/sales/customer_transaction_invoice')); 
const CustomerTransactionView = React.lazy(() => import('./components/sales/view_customer_tran_invoice')); 
const ProductReOrderList = React.lazy(() => import('./components/administration/product_re_order_list')); 
const MaterialReOrderList = React.lazy(() => import('./components/administration/material_re_order_list')); 
const ProductBarcode = React.lazy(() => import('./components/administration/product_barcode')); 
const CashStatement = React.lazy(() => import('./components/accounts/cash_statement')); 
const BalanceSheet = React.lazy(() => import('./components/accounts/balance_sheet')); 
const CashLedger = React.lazy(() => import('./components/accounts/cash_ledger')); 
const DailyLedger = React.lazy(() => import('./components/accounts/daily_ledger')); 
const ViewChalanInvoice = React.lazy(() => import('./components/sales/view_chalan_invoice')); 
const ServiceEntry = React.lazy(() => import('./components/sales/service_entry')); 
const ReceiptEntry = React.lazy(() => import('./components/diagnostic/receipt_entry')); 
const ViewReceiptVoucher = React.lazy(() => import('./components/diagnostic/view_receipt_voucher')); 
const QuickReceiptVoucher = React.lazy(() => import('./components/diagnostic/quick_receipt')); 
const ReceiptRecord = React.lazy(() => import('./components/diagnostic/receipt_record')); 
const DoctorEntry = React.lazy(() => import('./components/diagnostic/doctor_entry')); 
const AgentEntry = React.lazy(() => import('./components/diagnostic/agent_entry')); 
const PatientPayment = React.lazy(() => import('./components/administration/patient_payment')); 



const CashDepositWithDraw = React.lazy(() => import('./components/accounts/cash_deposit_withdraw')); 
const PatientBarcode = React.lazy(() => import('./components/administration/patient_barcode')); 
const AgentPayment = React.lazy(() => import('./components/administration/agent_payment')); 
const AgentDueList = React.lazy(() => import('./components/diagnostic/agent_due_list')); 
const PatientDueList = React.lazy(() => import('./components/diagnostic/patient_due_list')); 
const PatientPaymentInvoice = React.lazy(() => import('./components/diagnostic/patient_payment_invoice')); 
const dailyReport = React.lazy(() => import('./components/accounts/daily_report')); 
const agentPaymentInvoice = React.lazy(() => import('./components/diagnostic/agent_payment_invoice')); 
const patientRegister = React.lazy(() => import('./components/hospital/patient_register')); 
const patientBill = React.lazy(() => import('./components/hospital/patient_bill')); 
const patientTransaction = React.lazy(() => import('./components/hospital/patient_transaction')); 
const doctorTransaction = React.lazy(() => import('./components/hospital/doctor_transaction')); 
const hospitalPaymentInvoice = React.lazy(() => import('./components/hospital/patient_invoice')); 
const doctorInvoice = React.lazy(() => import('./components/hospital/doctor_invoice')); 
const cabinEntry = React.lazy(() => import('./components/hospital/cabin_entry')); 
const patientBillQuick = React.lazy(() => import('./components/hospital/quick_patient_bill')); 
const hospitalBillView = React.lazy(() => import('./components/hospital/hospital_bill_view')); 


const hospitalPatientDueList = React.lazy(() => import('./components/hospital/hospital_patient_due_list')); 
const doctorDueList = React.lazy(() => import('./components/hospital/doctor_due_list')); 
const billRecord = React.lazy(() => import('./components/hospital/bill_record')); 
const reg = React.lazy(() => import('./components/hospital/quick_reg')); 
const cabinList = React.lazy(() => import('./components/hospital/cabin_list')); 
const incomeHead = React.lazy(() => import('./components/hospital/income_head')); 
const billOfReg = React.lazy(() => import('./components/hospital/bill_of_reg')); 
const paraAdd = React.lazy(() => import('./components/diagnostic/para_add')); 
const reportEntry = React.lazy(() => import('./components/diagnostic/report_entry')); 
const quickReport = React.lazy(() => import('./components/diagnostic/quick_report')); 
const reportRecord = React.lazy(() => import('./components/diagnostic/report_record')); 
const reportRecordOk = React.lazy(() => import('./components/diagnostic/report_ok_record')); 
const doctorInfoAdd = React.lazy(() => import('./components/diagnostic/doctor_info_add')); 
const machineAdd = React.lazy(() => import('./components/diagnostic/machine_add')); 
const reportRecordComplete = React.lazy(() => import('./components/diagnostic/report_done_show'));

const agentComReport = React.lazy(() => import('./components/diagnostic/agent_com_report')); 
const marketer = React.lazy(() => import('./components/marketing/marketer')); 
const doctor = React.lazy(() => import('./components/marketing/doctor')); 
const appointment = React.lazy(() => import('./components/marketing/appointment')); 





const drawerWidth = 180;
const useStyles = makeStyles((theme)=>({
  rootApp:{
    backgroundColor:'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%);'
  },
  navTopSection:{
    height:'7px',
    width:'100%',
    background: '#9CE1E7'
  },
  navTopSection:{
    height:'7px',
    background: '#9CE1E7'
  },
root: {
display: 'flex',
},
toolbarspace:{
display: 'flex',
alignItems: 'center',
justifyContent: 'flex-end',
padding: theme.spacing(0, 1),
...theme.mixins.toolbar,
},
content: {
flexGrow: 1,
padding: theme.spacing(3),
}
}))


const App = ({authInfo,currentRouteSet,currentRoute,authInfoSet})=>{
    useEffect(()=>{
      currentRouteSet(pathSpliter(window.location.pathname,1))
      authInfoSet(authInfoSeter);
    },[]);


    let classes = useStyles(); 
    
  return (
        <Router> 
        {
          authInfo!=null?
          (
          <Fragment>
           
         <div className={classes.navTopSection}></div>
         <div className={classes.rootApp} >
         <div className={classes.root}>
         <Header/> 

         <main className={classes.content} >
          <div className={classes.toolbarspace}/> 
          <Suspense fallback={<b>Loading...</b>}> 
             <Route exact path="/" component={Dashboard} />
             <Route exact path="/sales"  component={ModuleDetail} />
             <Route exact path="/hospital"  component={ModuleDetail} />
             <Route exact path="/diagnostic"  component={ModuleDetail} />
             <Route exact path="/preport"  component={ModuleDetail} />
             <Route exact path="/service"  component={ModuleDetail} />
             <Route exact path="/delivery"  component={ModuleDetail} />
             <Route exact path="/marketing"  component={ModuleDetail} />


             <Route exact path="/marketing/marketer"  component={marketer} />
             <Route exact path="/marketing/appointment"  component={appointment} />
             <Route exact path="/marketing/appointment-record"  component={appointment_record} />
             <Route exact path="/hospital/doctor"  component={doctor} />

             
             <Route exact path="/diagnostic/patient-payment-invoice/:id"  component={PatientPaymentInvoice} />
             <Route exact path="/diagnostic/daily-report"  component={dailyReport} />
             <Route exact path="/sales/sales-invoice"  component={ViewSalesInvoice} />
             <Route exact path="/service/service-invoice"  component={ViewServiceInvoice} />
             <Route exact path="/reports/sales-invoice"  component={ViewSalesInvoice} />
             <Route exact path="/sales/chalan-invoice/:id"  component={ViewChalanInvoice} />
             <Route exact path="/sales/customer-transation-history"  component={CustomerTransactionHistory} />
             <Route exact path="/reports/customer-transation-history"  component={CustomerTransactionHistory} />
             <Route exact path="/sales/sales-return-list"   component={salesReturnList} />
             <Route exact path="/reports/sales-return-list"   component={salesReturnList} />
             <Route exact path="/accounts/customer-transaction/:id"   component={CustomerTransactionInvoice} />
             <Route exact path="/accounts/customer-transactions"   component={CustomerTransactionView} />
             <Route exact path="/service/service-entry"   component={ServiceEntry} /> 
             <Route exact path="/service/service-update/:id"  component={ServiceEntry} />
             <Route exact path="/diagnostic/receipt-voucher"  component={ViewReceiptVoucher} />
             <Route exact path="/diagnostic/quick-receipt-voucher/:id"  component={QuickReceiptVoucher} />
             <Route exact path="/diagnostic/receipt-record"  component={ReceiptRecord} />
             <Route exact path="/diagnostic/doctor-entry"  component={DoctorEntry} />
             <Route exact path="/diagnostic/agent-entry"  component={AgentEntry} />
             <Route exact path="/diagnostic/patient-payment"  component={PatientPayment} />
             <Route exact path="/diagnostic/agent-payment"  component={AgentPayment} />
             <Route exact path="/diagnostic/agent-due-list"  component={AgentDueList} />
             <Route exact path="/diagnostic/patient-due-list"  component={PatientDueList} />
             <Route exact path="/diagnostic/agent-payment-invoice/:id"  component={agentPaymentInvoice} />
             <Route exact path="/hospital/doctor-invoice/:id"  component={doctorInvoice} />
             <Route exact path="/hospital/cabin-entry"  component={cabinEntry} />
             <Route exact path="/hospital/view-patient-bill"  component={hospitalBillView} />
             <Route exact path="/hospital/income-head"  component={incomeHead} /> 
             <Route exact path="/hospital/bill-of-reg"  component={billOfReg} /> 
             <Route exact path="/preport/para-add"  component={paraAdd} /> 
             <Route exact path="/preport/quick-report/:id"  component={quickReport} /> 
             <Route exact path="/preport/quick-report/:id/:id"  component={quickReport} /> 
             <Route exact path="/preport/report-record"  component={reportRecord} /> 
             <Route exact path="/preport/doctor-info-add"  component={doctorInfoAdd} /> 
             <Route exact path="/preport/machine-add"  component={machineAdd} /> 
             <Route exact path="/delivery/report-record-ok"  component={reportRecordOk} /> 
             <Route exact path="/preport/report-record-complete"  component={reportRecordComplete} /> 
             <Route exact path="/diagnostic/agent-com-report"  component={agentComReport} /> 

 
             <Route exact path="/stock"  component={ModuleDetail} />
             
             <Route exact path="/stock/product-stock-report"  component={StockReport} />
             <Route exact path="/reports/product-stock-report"  component={StockReport} />
             <Route exact path="/administration/user-access/:id"  component={UserAccess} />

             <Route exact path="/reports/cash_bank_balance"  component={CashBankBalance} />
             <Route exact path="/reports/profit-loss"  component={ProfitLoss} />
             <Route exact path="/diagnostic/receipt-entry"  component={ReceiptEntry} />
             <Route exact path="/diagnostic/receipt-update/:id"  component={ReceiptEntry} />
             <Route exact path="/preport/report-entry"  component={reportEntry} />
             <Route exact path="/preport/report-entry/:id"  component={reportEntry} />


             {/* Production Routers */}
             <Route exact path="/hospital/patient-register"  component={patientRegister} />
             <Route exact path="/hospital/patient-bill"  component={patientBill} />
             <Route exact path="/hospital/patient-transaction"  component={patientTransaction} />
             <Route exact path="/hospital/doctor-transaction"  component={doctorTransaction} />
             <Route exact path="/hospital/hospital-patient-invoice/:id"  component={hospitalPaymentInvoice} />
             <Route exact path="/hospital/quick-patient-bill/:id"  component={patientBillQuick} />
             <Route exact path="/hospital/reg/:id"  component={reg} />
             <Route exact path="/hospital/patient-bill/:id"  component={patientBill} />

             <Route exact path="/hospital/patient-due-list"  component={hospitalPatientDueList} />
             <Route exact path="/hospital/doctor-due-list"  component={doctorDueList} />
             <Route exact path="/hospital/bill-record"  component={billRecord} />
             

             <Route exact path="/production"  component={ModuleDetail} />
             <Route exact path="/production/material-entry"  component={MaterialEntry} />
             <Route exact path="/administration/material-entry"  component={MaterialEntry} />
             <Route exact path="/production/material-name-entry"  component={MaterialNameEntry} />
             <Route exact path="/administration/material-name-entry"  component={MaterialNameEntry} />
             <Route exact path="/production/material-damage-entry"  component={MaterialDamageEntry} />
             <Route exact path="/stock/material-damage-entry"  component={MaterialDamageEntry} />
             <Route exact path="/production/material-purchase-entry"  component={MaterialPurchaseEntry} />
             <Route exact path="/production/production-record"  component={MaterialProductionRecord} />
             <Route exact path="/reports/production-record"  component={MaterialProductionRecord} />
             <Route exact path="/production/material-purchase-update/:id"  component={MaterialPurchaseEntry} />
             <Route exact path="/production/production-entry-update/:id"  component={ProductionEntry} />
             <Route exact path="/production/production-entry"  component={ProductionEntry} />
             <Route exact path="/production/material-purchase-record"  component={MaterialPurchaseRecord} />
             <Route exact path="/reports/material-purchase-record"  component={MaterialPurchaseRecord} />
             <Route exact path="/production/material-damage-list"  component={MaterialDamageList} />
             <Route exact path="/reports/material-damage-list"  component={MaterialDamageList} />
             <Route exact path="/stock/material-damage-list"  component={MaterialDamageList} />
             <Route exact path="/production/material-list"  component={MaterialList} />
             <Route exact path="/reports/material-list"  component={MaterialList} />
             <Route exact path="/production/material-purchase-invoice"  component={MaterialPurchaseInvoice} />
             <Route exact path="/production/material-purchase-invoice/:id"  component={MaterialPurchaseQuickInvoice} />
             <Route exact path="/reports/material-purchase-invoice"  component={MaterialPurchaseInvoice} />
             <Route exact path="/production/production-invoice"  component={ProductionPurchaseInvoice} />
             <Route exact path="/reports/production-invoice"  component={ProductionPurchaseInvoice} />
             <Route exact path="/production/production-invoice/:id"  component={ProductionQuickInvoice} />
             <Route exact path="/production/material-stock"  component={MaterialStockReport} />
             <Route exact path="/stock/material-ledger"  component={MaterialLedgerReport} />
             <Route exact path="/reports/material-stock"  component={MaterialStockReport} />
             <Route exact path="/stock/material-stock"  component={MaterialStockReport} />
             <Route exact path="/production/material-ledger"  component={MaterialLedgerReport} />
             <Route exact path="/reports/material-ledger"  component={MaterialLedgerReport} />
             <Route exact path="/reports/customer-transactions"  component={CustomerTransactionView} />
             <Route exact path="/reports/cash_statement"  component={CashStatement} />
             <Route exact path="/reports/balance_sheet"  component={BalanceSheet} />
              

             {/* End  */}

             {/* Purchase Routes  */}
             <Route exact path="/purchase"  component={ModuleDetail} />
             <Route exact path="/purchase/purchase-entry"  component={PurchaseEntry} />
             <Route exact path="/service/service-purchase-entry"  component={servicePurchaseEntry} />
             <Route exact path="/service/service-purchase-update/:id"  component={servicePurchaseEntry} />
             <Route exact path="/purchase/purchase-update/:id"  component={PurchaseEntry} />
             <Route exact path="/purchase/purchase-invoice/:id"  component={PurchaseInvoiceQuick} />
             <Route exact path="/service/service-item-invoice/:id"  component={ServiceItemInvoiceQuick} />
             <Route exact path="/purchase/purchase-invoice"  component={ViewPurchaseInvoice} />
             <Route exact path="/service/service-item-invoice"  component={ViewServiceItemInvoice} />
             <Route exact path="/reports/purchase-invoice"  component={ViewPurchaseInvoice} />
             
             <Route exact path="/purchase/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/service/service-expense-record"  component={ServiceExpenseRecord} />
             <Route exact path="/service/services-report"  component={ServiceReport} />
             <Route exact path="/reports/purchase-record"  component={PurchaseRecord} />
             <Route exact path="/purchase/purchase-return"  component={PurchaseReturn} />
             <Route exact path="/stock/product-damage"  component={ProductDamage} />

             <Route exact path="/hrpayroll/salary-payment"  component={SalaryPayment} />
             <Route exact path="/hrpayroll/salary-payment-report"  component={SalaryPaymentReport} />
             <Route exact path="/reports/salary-payment-report"  component={SalaryPaymentReport} />
             <Route exact path="/sales/customer-ledger"  component={CustomerLedger} />
             <Route exact path="/reports/customer-ledger"  component={CustomerLedger} />
             <Route exact path="/purchase/supplier-ledger"  component={SupplierLedger} />
             <Route exact path="/reports/supplier-ledger"  component={SupplierLedger} />
             <Route exact path="/purchase/purchase-return-list"  component={PurchaseReturnList} />
             <Route exact path="/reports/purchase-return-list"  component={PurchaseReturnList} />
             
             <Route exact path="/purchase/supplier-due-list"  component={supplierDueList} />
             <Route exact path="/reports/supplier-due-list"  component={supplierDueList} />
             <Route exact path="/purchase/supplier-list"  component={supplierList} />
             <Route exact path="/reports/supplier-list"  component={supplierList} />
             <Route exact path="/purchase/supplier-transaction-history"  component={SupplierTransactionHistory} />
             <Route exact path="/reports/supplier-transaction-history"  component={SupplierTransactionHistory} />
             <Route exact path="/accounts/cash-transaction-report"  component={cashTransactionReport} />
             <Route exact path="/reports/cash-transaction-report"  component={cashTransactionReport} />
             <Route exact path="/accounts/bank-transaction-report"  component={bankTransactionReport} />
             <Route exact path="/reports/cash-ledger"  component={CashLedger} />
             <Route exact path="/accounts/cash-ledger"  component={CashLedger} />
             <Route exact path="/accounts/daily-ledger"  component={DailyLedger} />
             <Route exact path="/reports/daily-ledger"  component={DailyLedger} />
             <Route exact path="/accounts/cash-deposit-withdraw"  component={CashDepositWithDraw} />
             
             
             <Route exact path="/reports/bank-transaction-report"  component={bankTransactionReport} />
             


             <Route exact path="/accounts"  component={ModuleDetail} />
             <Route exact path="/hrpayroll"  component={ModuleDetail} />
             <Route exact path="/reports"  component={ModuleDetail} />
             <Route exact path="/administration"  component={ModuleDetail} />
             <Route exact path="/sales/sales-entry"  component={SalesEntry} />
             <Route exact path="/diagnostic/patient-barcode/:id"  component={PatientBarcode} />
             <Route exact path="/sales/sales-invoice/:id"  component={SalesInvoiceQuick} />
             <Route exact path="/service/service-invoice/:id"  component={ServiceInvoiceQuick} />
             <Route exact path="/sales/sales-update/:id"  component={SalesEntry} />
             <Route exact path="/sales/sales-record"  component={SalesRecord} />
             <Route exact path="/service/service-record"  component={ServiceRecord} />
             <Route exact path="/reports/sales-record"  component={SalesRecord} />
             <Route exact path="/sales/sales-return"  component={SalesReturn} />
             <Route exact path="/sales/customer-list"  component={CustomerList} />
             <Route exact path="/reports/customer-list"  component={CustomerList} />
             <Route exact path="/sales/customer-due-list"  component={CustomerDueList} />
             <Route exact path="/reports/customer-due-list"  component={CustomerDueList} />
             <Route exact path="/hospital/cabin-list"  component={cabinList} />

             {/*  */}
             <Route exact path="/sales/quotation-entry"  component={QuotationEntry} />
             <Route exact path="/sales/quotation-update/:id"  component={QuotationEntry} />
             <Route exact path="/sales/quotation-invoice"  component={QuotationInvoice} />
             <Route exact path="/reports/quotation-invoice"  component={QuotationInvoice} />
             <Route exact path="/sales/quotation-invoice/:id"  component={QuotationQuickInvoice} />
             <Route exact path="/sales/quotation-record"  component={QuotationRecord} />
             <Route exact path="/reports/quotation-record"  component={QuotationRecord} />
             
             {/*  */}
             <Route exact path="/stock/product-list"  component={ProductList} />
             <Route exact path="/stock/product-re-order-list"  component={ProductReOrderList} />
             <Route exact path="/reports/product-re-order-list"  component={ProductReOrderList} />
             <Route exact path="/reports/material-re-order-list"  component={MaterialReOrderList} />
             <Route exact path="/stock/material-re-order-list"  component={MaterialReOrderList} />
             <Route exact path="/reports/product-list"  component={ProductList} /> 
             <Route exact path="/administration/product-barcode/:id"  component={ProductBarcode}  />
             <Route exact path="/administration/prod-category-manage"  component={ProdCategoriesManage} />
             <Route exact path="/administration/institution-profile"  component={InstitutionProfile} />
             <Route exact path="/sales/customers-manage"  component={CustomersManage} />
             <Route exact path="/service/customers-manage"  component={CustomersManage} />
             <Route exact path="/service/suppliers-manage"  component={SuppliersManage} />
             <Route exact path="/purchase/suppliers-manage"  component={SuppliersManage} />
             <Route exact path="/production/suppliers-manage"  component={SuppliersManage} />
             <Route exact path="/administration/prod-units-manage"  component={prodUnitsManage} />
             <Route exact path="/administration/areas-manage"  component={AreasManage} />
             <Route exact path="/marketing/areas-manage"  component={AreasManage} />
             <Route exact path="/administration/prod-brands-manage"  component={ProdBrandsManage} />
             <Route exact path="/administration/prod-colors-manage"  component={ProdColorsManage} />
             <Route exact path="/administration/branches-manage"  component={BranchesManage} />
             <Route exact path="/administration/prod-names-manage"  component={ProdNameManage} />
             <Route exact path="/stock/product-transfer"  component={productTransfer} />
             <Route exact path="/stock/product-ledger"  component={productLedger} />
             <Route exact path="/reports/product-ledger"  component={productLedger} />
             <Route exact path="/stock/product-transfer/:id"  component={productTransfer} />
             <Route exact path="/stock/transfer-invoice/:id"  component={TransferInvoiceQuick} />
             <Route exact path="/stock/transfer-record"  component={productTransferRecord} />
             <Route exact path="/reports/transfer-record"  component={productTransferRecord} />
             <Route exact path="/stock/receive-record"  component={ProductReceiveRecord} />
             <Route exact path="/reports/receive-record"  component={ProductReceiveRecord} />
             <Route exact path="/stock/product-damage-list"  component={ProductDamageList} />
             <Route exact path="/reports/product-damage-list"  component={ProductDamageList} />

             <Route exact path="/administration/warehouses-manage"  component={warehousesManage} />
             <Route exact  path="/administration/users-manage"  component={UsersManage} />
             <Route exact  path="/administration/products-manage"  component={ProductsManage} />
             <Route exact  path="/hrpayroll/designations-manage"  component={DesignationsManage} />
             <Route exact  path="/hrpayroll/employees-manage"  component={EmployeesManage}/> 
             <Route exact  path="/hrpayroll/departments-manage"  component={DepartmentsManage} />
             <Route exact  path="/hrpayroll/months-manage"  component={MonthsManage} />
             <Route exact  path="/accounts/transaction-accounts-manage"  component={TransactionAccountsManage} />
             <Route exact  path="/accounts/bank-accounts-manage"  component={BankAccountsManage} />
             <Route exact  path="/accounts/cash-transactions-manage"  component={cashTransactionManage} />
             <Route exact  path="/accounts/bank-transactions-manage"  component={BankTransactionManage} />
             <Route exact  path="/accounts/customer-payments-manage"  component={CustomerPayments} />
             <Route exact  path="/accounts/supplier-payments-manage"  component={SupplierPayments} />
             <Route exact  path="/accounts/bank-ledger"  component={BankLedger} />
             <Route exact path="/accounts/customer-transation-history"  component={CustomerTransactionHistory} />
             <Route exact path="/accounts/supplier-transation-history"  component={SupplierTransactionHistory} />
             <Route exact path="/stock/material-damage"  component={MaterialDamageEntry} />

             <Route exact  path="/reports/bank-ledger"  component={BankLedger} />
             </Suspense>
          </main> 
          </div>
         </div>
         </Fragment>
            
          ):(   
          <div>
                <Suspense fallback={<b style={{textAlign:'center'}}>Loading...</b>}>
                <Route exact path="/" component={SignIn} />
                </Suspense>
                </div>
          )
            
            
           
        }
       
        </Router>
  );
}
const mapStateToPops = (state)=>{
  return {
    currentRoute:state.currentRouteReducer,
    authInfo:state.authInfoReducer
  }
}
export default connect(mapStateToPops,{currentRouteSet,authInfoSet})(App);